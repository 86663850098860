import { NavLink } from "react-router-dom";
import  './card.css'
export const CardLink = (props) => {
  // props: link, title, describe
  return (
    <>
      <NavLink className="cardComponent block m-2 p-8 text-left bg-inherit border border-gray-200 rounded-lg max-w-adp hover:border-blue-400 dark:border-blue-400" to={props.link} exact>
  
        <h3 className="mb-2 text-2xl font-bold font-['system-ui, apple-system'] text-stone-900 tracking-tight dark:text-white">{props.title}</h3>
        <p className="font-normal text-gray-700 text-lg font-['system-ui, apple-system'] text-stone-900 dark:text-white">{props.describe}</p>
    </NavLink>
    </>
  )
}

export const CardLinkA = (props) => {
  // props: link, title, describe
  return (
    <>
      <a className="cardComponent block m-2 p-8 text-left  bg-inherit border border-gray-200 rounded-lg hover:border-blue-400 max-w-adp dark:border-blue-400" href={props.link}>
     
	    <h3 className="mb-2 text-2xl font-bold tracking-tight font-['system-ui, apple-system'] text-stone-900 dark:text-white">{props.title}</h3>
      <p className="font-normal text-gray-700 text-lg font-['system-ui, apple-system'] text-stone-900 dark:text-white">{props.describe}</p>
     
    </a>

    </>
  )
}

