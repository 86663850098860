import {BrowserRouter, Route, Routes as Switch, Navigate} from "react-router-dom";
import { Home, Article } from "../pages";
import { NotFound } from "../shared/components/index"; 
export const Routes = () => {
   return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Home/>} />
        <Route path="/artigos" element={<Article/>} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFound/>} />
    </Switch>
    </BrowserRouter>

   );
}