import arrow from '../../shared/public/arrow-back.png'
import arrow_white from '../../shared/public/arrow-white.png'
import { NavLink } from "react-router-dom";
import { useTheme } from '../../shared/hooks/useTheme'

export const Article = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="flex flex-col justify-between gap-14 p-20">

      <NavLink className="w-8" to="/" exact>
      {theme === "ligth" 
      ? <img className="cursor-pointer" src={arrow} alt="" />
      :  <img className="cursor-pointer w-6" src={arrow_white} alt="" />
      }
        
      </NavLink>

      <div className="flex flex-col gap-4">
        <h1 className="font-sans text-4xl dark:text-white ">Artigos</h1>
        <p className="text-xl dark:text-white">Meus pensamentos sobre desenvolvimento web.</p>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-blue-300"/>
      
      <div className="flex flex-col gap-2">
        <h1 className="font-sans text-4xl dark:text-white">Machine learning para aferição de temperatura</h1>
        <div className="flex flex-row gap-2">
          <div className=" border border-black rounded-sm w-20 flex flex-row justify-center px-2 dark:border-blue-400">
            <p className="dark:text-white">python</p>
          </div>
          <div className=" border border-black rounded-sm px-2 flex flex-row justify-center dark:border-blue-400">
            <p className="dark:text-white">Deep learning</p>
          </div>
        </div>
        <p className="text-sm dark:text-white">02 Setembro 2022 / 4 minutos de leitura</p>

        <p className="text-xl dark:text-white">Esse artigo tem como objetivo mostrar a construção de um modelo de deep learning para aferição de temperatura.</p>

        <a href="#as" className="text-blue-700 hover:text-blue-900">Leia o artigo</a>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-blue-300"/>
    </div>
  )
}