
import avatar from '../../shared/public/montanha.jpg'
import { Footer } from '../../shared/components'
import {CardLink, CardLinkA} from './components/CardLink'
import { useTheme } from '../../shared/hooks/useTheme'

import dark from '../../shared/public/lua.svg'
import ligth from '../../shared/public/sol.svg'

export const Home = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="flex flex-col justify-between content-center gap-10 items-center">

    <div className="absolute inset-y-0 left-0 w-16 top-24 sm:hidden">
    <div className="flex items-center justify-center  bg-blue-300 w-10 h-10 rounded-r-2xl dark:bg-slate-700">
      <div className="flex items-center justify-center rounded-full w-5 h-5 bg-slate-100 dark:bg-black">
        {theme === "ligth" ?  
        <img className="cursor-pointer" src={dark} alt="" onClick={()=>setTheme("dark")} /> : 
        <img className="cursor-pointer" src={ligth} alt="" onClick={()=>setTheme("ligth")} />}
      </div>
    </div>
    </div>

    <div className="relative w-48 h-48 py-10">
        <img className="rounded-full shadow-sm" src={avatar} alt="Foto de perfil de César Augusto" />
    </div>

    <div className="flex flex-col justify-between content-center gap-4 items-center py-4">
    <h1 className="text-5xl font-['system-ui, apple-system'] font-bold text-stone-900 dark:text-white">César Augusto</h1>
    <p className="text-xl font-['system-ui, apple-system'] text-stone-900 dark:text-white text-center">Software Engineer</p>
    </div>
  

    <div className="flex flex-row flex-wrap gap-4 mt-5 justify-center content-center  items-center">

    <div className="flex flex-col flex-wrap gap-4 justify-center items-center">

    <CardLink link='/projetos' title='Projetos' describe='Veja alguns projetos e tecnológias
        que já trabalhei.' />
    
    <CardLink link='/contato' title='Contato' describe='Entre em contato para propostas e business.' />
    </div>

    <div className="flex flex-col flex-wrap gap-4 justify-center items-center">

    <CardLinkA link='https://cesaraugustomt.github.io/blog/' title='Blog' describe='Um casa para ideias mal pesquisadas.' />

    <CardLink link='/artigos' title='Artigos' describe='Veja todos os artigos que já 
      publiquei.' />
       </div>
    
    </div>

    <Footer />
  </div>
  )
}