import github from '../public/github.svg'
import linkedin from '../public/linkedin.svg'
import lates from '../public/lates.svg'
import github_ligth from '../public/github-ligth.svg'
import linkedin_ligth from '../public/linkedin-ligth.svg'
import lates_ligth from '../public/lates-ligth.svg'

import { useTheme } from '../../shared/hooks/useTheme'

export const Footer = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="bg-blue-100 w-full h-11 h-22 flex flex-row justify-center items-center gap-16 dark:bg-black">
         <a href="https://github.com/cesaraugustomt" target="blank">
          { theme === "ligth" 
          ? <img className="w-6" src={github} alt="" onClick={()=>setTheme("dark")} />
          : <img className="w-6" src={github_ligth} alt="" onClick={()=>setTheme("ligth")} /> }
         
         </a>
         <a href="https://www.linkedin.com/in/cesar-augusto-dev-br/" target="blank">
         { theme === "ligth" 
          ? <img className="w-6" src={linkedin} alt="" onClick={()=>setTheme("dark")} />
          : <img className="w-6" src={linkedin_ligth} alt="" onClick={()=>setTheme("ligth")} /> }
        
         </a>
        <a href="https://lattes.cnpq.br/1956814225627808" target="blank">
        { theme === "ligth" 
          ? <img className="w-6" src={lates} alt="" onClick={()=>setTheme("dark")} />
          : <img className="w-6" src={lates_ligth} alt="" onClick={()=>setTheme("ligth")} /> }
       
        </a>
         
    </div>
  )
}